import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.js";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { formatDuration } from "@/app/util.mjs";
import type { MapStats } from "@/game-destiny2/components/Profile/types.js";
import type { GuardianMapStats } from "@/game-destiny2/models/destiny2-player-map-stats.mjs";
import { getGuardianClassAggregates } from "@/game-destiny2/utils/get-map-aggregates.mjs";
import BlitzLogoLoading from "@/shared/BlitzLogoLoading.jsx";
import DataTable from "@/shared/DataTable.jsx";
import { SORT_DESC } from "@/shared/InfiniteTable.jsx";
import clone from "@/util/clone.mjs";
import { useEvalState } from "@/util/eval-state.mjs";
import { displayRate, sanitizeNumber } from "@/util/helpers.mjs";
import { formatToFixedNumber } from "@/util/i18n-helper.mjs";
import isEmpty from "@/util/is-empty.mjs";
import { lazyComponent } from "@/util/lazy-component.mjs";
import { useQuery, useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const MapsTopStatCards = lazyComponent(
  () => import("@/game-destiny2/components/Profile/MapsTopStatCards.jsx"),
);

const ONE_HOUR = 1000 * 60 * 60;

export default function Maps() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    parameters: [bungieId],
  } = useRoute();
  const state = useSnapshot(readState);
  const { seasons, mapStats, profiles } = state.destiny2;
  const [guardian] = useQuery<string>("guardian");
  const [seasonId = seasons[0]?.id] = useQuery("season");
  const $profile = profiles[bungieId];
  const profile = useEvalState($profile);
  const guardians = useMemo(
    () => profile?.memberships.flatMap?.((i) => i.guardians) ?? [],
    [profile?.memberships],
  );
  const mapsByCharacter = guardians.reduce((acc, cur) => {
    const value = mapStats[btoa(seasonId + cur.characterId)];
    if (!value || value instanceof Error) return acc;
    acc.push(value);
    return acc;
  }, []) as Array<GuardianMapStats["0"]>;
  const maps = useMemo(() => {
    // The expected output of this is to have key value pairs of map name to map stats
    const hash = {}; // Eg. { mapName: {{ mapStats }} }
    for (const maps of mapsByCharacter) {
      for (const map in maps) {
        const data = clone(maps[map]);
        if (isEmpty(data)) continue;
        // This is strictly just for a global filter for the selected guardian class
        if (guardian)
          for (const k in data)
            if (data[k].guardian.guardianClass !== guardian) delete data[k];
        const result = getGuardianClassAggregates(data);
        if (hash[map]) {
          for (const k in hash[map]) {
            hash[map][k] = (hash[map][k] ?? 0) + result[k];
          }
        } else {
          hash[map] = result;
        }
      }
    }
    return Object.entries(hash).filter(
      (i) => (i[1] as MapStats).secondsPlayed, // Filter out maps with 0 time played
    ) as Array<[string, MapStats]>;
  }, [guardian, mapsByCharacter]);
  const cols = [
    {
      display: t("common:map", "Map"),
      align: "left",
      isStat: false,
    },
    {
      display: t("common:wins", "Wins"),
      align: "right",
      isStat: true,
    },
    {
      display: t("common:kills", "Kills"),
      align: "right",
      isStat: true,
    },
    {
      display: t("common:stats.hs", "HS"),
      align: "right",
      isStat: true,
    },
    {
      display: t("common:stats.hsPercent", "HS%"),
      align: "right",
      isStat: true,
    },
    {
      display: t("common:stats.assists", "Assists"),
      align: "right",
      isStat: true,
    },
    {
      display: t("common:stats.deaths", "Deaths"),
      align: "right",
      isStat: true,
    },
    {
      display: t("lol:timePlayed", "Time Played"),
      align: "right",
      isStat: true,
    },
  ];
  const rows =
    maps.map(([mapName, stats]) => {
      const timePlayed = sanitizeNumber(stats.secondsPlayed * 1000);
      const kills = stats.kills;
      return [
        {
          display: mapName,
          value: mapName,
        },
        {
          display: formatToFixedNumber(language, stats.wins),
          value: stats.wins,
        },
        {
          display: formatToFixedNumber(language, kills),
          value: kills,
        },
        {
          display: formatToFixedNumber(language, stats.precisionKills),
          value: stats.precisionKills,
        },
        {
          display: displayRate(stats.precisionKills, kills),
          value: sanitizeNumber(stats.precisionKills / kills),
        },
        {
          display: formatToFixedNumber(language, stats.assists),
          value: stats.assists,
        },
        {
          display: formatToFixedNumber(language, stats.deaths),
          value: stats.deaths,
        },
        {
          display:
            timePlayed > ONE_HOUR
              ? t("val:stats.hoursPlayed", "{{ hours }} hours", {
                  hours: formatDuration(timePlayed, "h:mm:ss"),
                })
              : t("common:minutes", "{{minutes}} mins", {
                  minutes: formatDuration(timePlayed, "m:ss"),
                }),
          value: stats.secondsPlayed,
        },
      ];
    }) ?? [];
  return (
    <div className="flex gap-4 column">
      {!!maps.length && (
        <MapsTopStatCards
          fallback={
            <div className="flex gap-4">
              {new Array(3).fill(undefined).map((_, idx) => (
                <div
                  className="relative w-full"
                  key={idx}
                  style={{ height: "180px" }} // Hard-coded magic value, this is just a loading state that approximately matches the end result UI
                >
                  <Card className="h-full" classNameOuter="h-full" />
                  <div className="absolute-center">
                    <BlitzLogoLoading />
                  </div>
                </div>
              ))}
            </div>
          }
          props={{
            maps,
            profileImgUrl: profile?.iconPath
              ? `${appURLs.BUNGIE}${profile.iconPath}`
              : "",
          }}
        />
      )}
      <DataTable
        cols={cols}
        rows={rows}
        indexCol
        noResultsTitle={["destiny2:suggestions.noMapStats", "No map stats"]}
        noResultsDescription={[
          "destiny2:suggestions.noMapStatsDescription",
          "We currently do no have any map stats for you.",
        ]}
        sortDir={SORT_DESC}
        sortCol={1}
      />
    </div>
  );
}

export function meta() {
  return {
    title: [`destiny2:meta.profile.title`, `Destiny Performance Overview`],
    description: [
      `destiny2:meta.profile.description`,
      `View your Destiny 2 profile and see how you perform.`,
    ],
  };
}
