import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";
import { Select } from "clutch/src/Select/Select";

import { readState } from "@/__main__/app-state.mjs";
import { populateMatchHistory } from "@/game-destiny2/fetches/bungie-activities.mjs";
import { refs } from "@/game-destiny2/refs.mjs";
import useUserProfile from "@/game-destiny2/utils/use-current-user-profile.mjs";
import { TimeAgo } from "@/shared/Time.jsx";
import { useQuery, useRoute } from "@/util/router-hooks.mjs";
import titleCase from "@/util/title-case.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Container = styled("div")`
  display: flex;
  gap: var(--sp-3);
  padding-bottom: var(--sp-3);
`;
const cssCapitalized = () => css`
  text-transform: capitalize;
`;

export const ProfileFilters = () => {
  const {
    parameters: [bungieId],
  } = useRoute();
  const state = useSnapshot(readState);
  const seasons = state.destiny2.seasons;
  const [guardianFilter, setGuardianFilter] = useQuery("guardian", "all");
  const [seasonFilter, setSeasonFilter] = useQuery("season", "all");
  const { guardians } = useUserProfile(bungieId);
  const uniqueGuardianSelectValues = guardians.reduce((acc, guardian) => {
    if (!acc.find((item) => item.value === guardian.class)) {
      const lower = guardian.class.toLowerCase();
      acc.push({
        value: guardian.class,
        text: [`destiny2:profileFilters.${lower}`, titleCase(guardian.class)],
      });
    }
    return acc;
  }, []);
  return (
    <Container>
      <div className="flex gap-2 justify-between w-full">
        <div className="flex gap-2">
          <Select
            options={[
              {
                value: "all",
                text: ["destiny2:profileFilters.all", "All guardians"],
              },
              ...uniqueGuardianSelectValues,
            ]}
            selected={guardianFilter}
            onChange={setGuardianFilter}
            containerClassName={cssCapitalized()}
          />
          <Select
            options={[
              {
                value: "all",
                text: ["destiny2:profileFilters.all", "All seasons"],
              },
              ...seasons.map((i) => ({
                value: i.id,
                text: [
                  "apex:season",
                  "Season {{number}}",
                  {
                    number: i.season,
                  },
                ] as Translation,
              })),
            ]}
            selected={seasonFilter}
            onChange={setSeasonFilter}
          />
        </div>
        <LastMatchHistoryUpdate />
      </div>
    </Container>
  );
};

const REFETCH_DELAY_SECONDS = 120;
const COUNTDOWN_INTERVAL = 1e3;
type LocalState = {
  lastUpdated: undefined | number;
  timer: number;
};
function _getLocalState(bungieId: string): LocalState {
  return {
    lastUpdated: refs.lastUpdatedMatchHistory[bungieId] ?? Date.now(),
    timer: (() => {
      if (
        typeof refs.lastUpdatedMatchHistory[bungieId] === "number" &&
        Date.now() <
          refs.lastUpdatedMatchHistory[bungieId] + REFETCH_DELAY_SECONDS
      ) {
        return 0;
      }
      return REFETCH_DELAY_SECONDS;
    })(),
  };
}
function LastMatchHistoryUpdate() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const ref = useRef(null);
  const {
    parameters: [bungieId],
  } = useRoute();
  const [state, setState] = useState<LocalState>(_getLocalState(bungieId));
  const handleUpdate = useCallback(() => {
    populateMatchHistory(bungieId);
    const lastUpdated = Date.now();
    refs.lastUpdatedMatchHistory[bungieId] = lastUpdated;
    setState({
      lastUpdated,
      timer: REFETCH_DELAY_SECONDS,
    });
  }, [bungieId]);
  const cleanup = useCallback(() => {
    if (ref.current) clearTimeout(ref.current);
    ref.current = null;
  }, []);
  useEffect(() => {
    // If swapped to a different user, update local state
    setState(_getLocalState(bungieId));
  }, [bungieId]);
  useEffect(() => {
    // Triggers the countdown if any is specified
    if (state.timer) {
      ref.current = setTimeout(function tick() {
        setState((prev) => {
          if (prev.timer <= 0) {
            cleanup();
            return prev;
          }
          return {
            ...prev,
            timer: prev.timer - 1,
          };
        });
        ref.current = setTimeout(tick, COUNTDOWN_INTERVAL);
      }, COUNTDOWN_INTERVAL);
    }
    return () => {
      cleanup();
    };
  }, [cleanup, state.timer]);
  return (
    <div className="flex gap-2 align-center">
      {!state.timer ? (
        <div className="type-caption">
          <span>{t("cs2:database.maps.lastUpdated", "Last Update")}</span>{" "}
          <TimeAgo date={new Date(state.lastUpdated)} />
        </div>
      ) : (
        <div className="type-caption">
          {t(
            "destiny2:suggestions.available",
            "Available to update in {{time}}s.",
            { time: state.timer.toLocaleString(language) },
          )}
        </div>
      )}
      <Button
        onClick={handleUpdate}
        disabled={state.timer > 0}
        data-tip={t(
          "destiny2:suggestions.syncProfileTooltip",
          "Enter the queue for profile sync, your stats will update soon!",
        )}
      >
        {t("destiny2:suggestions.syncProfile", "Queue profile sync")}
      </Button>
    </div>
  );
}
