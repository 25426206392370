import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/constants.mjs";
import { STATS_MAP } from "@/game-destiny2/constants/stats.mjs";
import type { CharacterModel } from "@/game-destiny2/models/destiny2-characters.mjs";
import type { Guardian as GuardianModel } from "@/game-destiny2/models/destiny2-guardian.mjs";
import { bungieAsset } from "@/game-destiny2/utils/api.mjs";
import calcKDA from "@/shared-fps/calc-kda.mjs";
import { displayRate } from "@/util/helpers.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const GuardiansContainer = styled("div")`
  display: grid;
  gap: var(--sp-3);
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  width: 100%;
`;
const GuardianContainer = styled("div")`
  flex: 1;
  background-color: var(--shade8);
  border: 1px solid var(--shade6);
  border-radius: var(--br-lg);
  overflow: hidden;

  .color-warlock {
    filter: sepia(1) saturate(10) hue-rotate(330deg);
  }
  .color-titan {
    filter: sepia(1) saturate(10) hue-rotate(0deg);
  }
  .color-hunter {
    filter: none;
  }

  .guardian-header {
    display: flex;
    flex-direction: column;
    gap: var(--sp-24);
    position: relative;
    padding: var(--sp-4);
    border-bottom: var(--sp-px) solid var(--shade6);

    .bg-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(${appURLs.CDN_PLAIN}/destiny2/destiny2-guardian-bg.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      opacity: 0.25;
    }

    .shade {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 60%,
        var(--shade8) 76%,
        var(--shade8) 100%
      );
    }

    .guardian-info {
      position: relative;
      display: grid;
      align-items: center;
      padding: 0 var(--sp-2);
      gap: var(--sp-2);
      grid-template-columns: var(--sp-16) 1fr;
    }

    .guardian-pfp {
      border-radius: 50%;
      width: var(--sp-16);
      height: var(--sp-16);
    }

    .guardian-light {
      font-size: var(--sp-4_5);
      font-weight: 700;
    }

    .guardian-class {
      text-transform: capitalize;
    }

    .player-stats {
      position: relative;
      display: flex;
      gap: var(--sp-2);
      justify-content: space-between;
    }
  }

  .section-title {
    font-size: var(--sp-3_5);
    font-weight: 700;
    color: var(--shade1);
    text-transform: uppercase;
  }

  .guardian-stats {
    display: flex;
    flex-direction: column;
    gap: var(--sp-2);
    padding: var(--sp-4);

    .stats-container {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: var(--sp-2);
    }

    .stat {
      display: flex;
      justify-content: space-between;
      padding-right: var(--sp-4);
    }

    .stat-name {
      text-transform: capitalize;
      color: var(--shade1);
    }

    .stat-value {
      font-weight: 700;
    }
  }
`;

const BG_DEFAULT = { className: "color-hunter", key: "hunter" };
const BG_COLOR_TRANSFORM = [
  BG_DEFAULT,
  { className: "color-titan", key: "titan" },
  { className: "color-warlock", key: "warlock" },
];

const Guardian = ({
  guardianClass,
  seasonAggregates,
  character,
}: GuardianModel & { character?: CharacterModel }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    kills = 0,
    precisionKills = 0,
    wins = 0,
    losses = 0,
    deaths = 0,
    assists = 0,
  } = seasonAggregates[0] ?? {};
  return (
    <GuardianContainer>
      <div className="guardian-header">
        <div
          className={`bg-img ${BG_COLOR_TRANSFORM.find((i) => guardianClass?.toLowerCase().includes(i.key))?.className ?? BG_DEFAULT.className}`}
        />
        <div className="shade" />
        <div className="guardian-info">
          <img
            className="guardian-pfp"
            src={bungieAsset(character?.character?.emblemPath)}
            alt="Guardian Background"
          />
          <span>
            <p className="guardian-light">
              {(character?.character?.light || 0).toLocaleString(language)}
            </p>
            <p className="guardian-class">{guardianClass}</p>
          </span>
        </div>
        <div className="player-stats">
          {[
            {
              label: t("common:stats.hsPercent", "HS%"),
              value: displayRate(precisionKills, kills),
            },
            {
              label: t("common:kda", "KDA"),
              value: calcKDA(kills, deaths, assists),
            },
            {
              label: t("common:stats.kills", "Kills"),
              value: kills.toLocaleString(language),
            },
            {
              label: t("common:matches", "Matches"),
              value: (wins + losses).toLocaleString(language),
            },
          ].map((i) => (
            <div key={i.label}>
              <div className="type-subtitle--bold">{i.label}</div>
              <div className="type-caption">{i.value}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="guardian-slots"></div>
      <div className="guardian-stats">
        <p className="section-title">{t("common:tab.stats", "Stats")}</p>
        <div className="stats-container">
          {Object.keys(character?.character?.stats ?? {})
            .filter((stat) => Boolean(STATS_MAP[stat]))
            .map((stat) => (
              <div key={stat} className="stat">
                <span className="stat-name">{STATS_MAP[stat] ?? stat}</span>
                <span className="stat-value">
                  {character?.character?.stats[stat] ?? ""}
                </span>
              </div>
            ))}
        </div>
      </div>
    </GuardianContainer>
  );
};

function EmptyGuardian({ isAlone }: { isAlone: boolean }) {
  const { t } = useTranslation();
  return (
    <Card className="h-full">
      <div
        className="type-body1-form"
        style={{
          paddingBottom: "var(--sp-2)",
        }}
      >
        {isAlone
          ? t("destiny2:suggestions.noGuardians", "No Guardians")
          : t("destiny2:suggestions.missingGuardians", "Missing Guardians?")}
      </div>
      <div className="type-caption--semi">
        {t(
          "destiny2:suggestions.noGuardiansPlayMatch",
          "Play some missions and come back here to see your guardian stats!",
        )}
      </div>
    </Card>
  );
}

export default function Guardians() {
  const {
    parameters: [bungieId],
  } = useRoute();
  const {
    destiny2: { guardians, characters },
  } = useSnapshot(readState);
  const guardiansObject = guardians?.[bungieId] ?? {};
  const profileGuardians = Object.values(guardiansObject).map((value) => ({
    // Shallow clone to prevent modifying the reference object since we're adding new properties onto the root object
    ...value,
    // Additional properties
    key: value.characterId, // Might as well declare the React key here since we're going to use this as the source reference for rendering
    character: characters?.[bungieId]?.[value.characterId],
  })) as Array<GuardianModel & { key: string; character?: CharacterModel }>;
  return (
    <GuardiansContainer>
      {profileGuardians.map((guardian) =>
        // Specifying the reference object via createElement instead of spreading it into the component
        React.createElement(Guardian, guardian),
      )}
      <EmptyGuardian isAlone={!profileGuardians.length} />
    </GuardiansContainer>
  );
}
