import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import useMatchHistoryData from "@/game-destiny2/utils/use-match-history-data.mjs";
import useProfileWeaponStats from "@/game-destiny2/utils/use-profile-weapon-stats.mjs";
import MatchHistoryHeader from "@/shared/MatchHistoryHeader.jsx";
import { calcRate, sanitizeNumber } from "@/util/helpers.mjs";
import rangeBucket from "@/util/range-bucket.mjs";

const winRateBucket = rangeBucket([
  [0, "var(--perf-neg3)"],
  [46, "var(--perf-neg2)"],
  [48, "var(--perf-neg1)"],
  [50, "var(--perf-neutral)"],
  [51, "var(--perf-pos1)"],
  [53, "var(--perf-pos2)"],
  [55, "var(--perf-pos3)"],
]);

export const getWinRateColor = (rate) =>
  typeof rate === "number" ? winRateBucket[rate * 100] : "var(--shade3)";

export default memo(function MatchHistoryHeaderDestiny({
  matchlist,
  bungieId,
}: {
  matchlist: Array<{ gameId }>;
  bungieId: string;
}) {
  // Hooks
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { wins, losses, matchLens, winRate, kda, score, hsPercent } =
    useMatchHistoryData(matchlist, bungieId);
  const weaponStatsByProfile = useProfileWeaponStats(bungieId);
  // Memo hooks
  const [totalCount, maxWeaponIdx] = useMemo(
    () =>
      weaponStatsByProfile.reduce(
        (acc, cur, i) => {
          return [
            acc[0] + cur.count,
            cur.count > weaponStatsByProfile?.[acc[1]]?.count ? i : acc[1],
          ];
        },
        [0, 0],
      ),
    [weaponStatsByProfile],
  );
  const statColumns = useMemo(() => {
    const statColumns = [
      {
        stat: t("common:stats.winsLosses", "{{wins}}W {{losses}}L", {
          wins: wins,
          losses: losses,
        }),
        description: t("val:lastNMatches", "Last {{matches}}", {
          matches: matchLens,
        }),
      },
      {
        stat: winRate.toLocaleString(language, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          style: "percent",
        }),
        description: t("common:stats.wr", "Winrate"),
        statColor: getWinRateColor(winRate),
      },
      {
        stat: kda ? kda : "- - -",
        description: t("common:stats.kda", "Avg. KDA"),
      },
      {
        stat: hsPercent
          ? hsPercent.toLocaleString(language, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              style: "percent",
            })
          : "- - -",
        description: t("common:stats.hsPercent", "Avg. HS%"),
        statColor: getWinRateColor(hsPercent),
      },
      {
        stat: score.toLocaleString(),
        description: t("common:stats.avgScore", "Avg. Score"),
      },
    ];
    if (weaponStatsByProfile?.length) {
      const weaponStat = weaponStatsByProfile[maxWeaponIdx];
      const topPickRate = calcRate(
        sanitizeNumber(weaponStat?.count),
        totalCount,
        2,
      );
      statColumns.push(
        {
          stat: weaponStat?.weapon.name ?? "",
          description: t("common:favorite.weapon", "Favorite Weapon"),
        },
        {
          stat: topPickRate.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            style: "percent",
          }),
          description: t("common:pick.rate", "Pick Rate"),
          statColor: getWinRateColor(topPickRate),
        },
      );
    }
    return statColumns;
  }, [
    hsPercent,
    kda,
    losses,
    matchLens,
    maxWeaponIdx,
    score,
    t,
    totalCount,
    weaponStatsByProfile,
    winRate,
    wins,
    language,
  ]);
  const matchBarGraph = useMemo(
    () => ({
      won: wins,
      lost: losses,
    }),
    [losses, wins],
  );
  return (
    <MatchHistoryHeader
      padding="var(--sp-5)"
      statColumns={statColumns}
      matchBarGraph={matchBarGraph}
    />
  );
});
