import { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import { filterErrState, useEvalState } from "@/util/eval-state.mjs";
import { calcRate } from "@/util/helpers.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

type LastNStats = {
  totalKills: number;
  precisionKills: number;
  assists: number;
  deaths: number;
  score: number;
  wins: number;
  losses: number;
  kda: number;
  winRate: number;
  hsPercent: number;
  matchLens: number;
};

const MAX_MATCHES = 20;

const useMatchHistoryData = (
  matchlist: Array<{ gameId }>,
  bungieId: string,
): LastNStats => {
  const {
    destiny2: { match: matches, profiles },
  } = useSnapshot(readState);
  const profile = useEvalState(profiles[bungieId]);
  const profileGuardians =
    profile?.memberships.flatMap?.((i) => i.guardians) ?? [];
  const stats = useMemo(() => {
    const playerMatches = matchlist
      .slice(0, MAX_MATCHES)
      .map(({ gameId }) =>
        filterErrState(matches[gameId])?.playerMatches.find((i) =>
          profileGuardians.find(
            (j) => j.characterId === i.guardian.characterId,
          ),
        ),
      )
      .filter((i) => i);

    const mainStats = playerMatches.reduce(
      (acc, match) => {
        acc.totalKills += match.kills;
        acc.precisionKills += match.precisionKills;
        acc.assists += match.assists;
        acc.deaths += match.deaths;
        acc.score += match.score;
        acc.wins += match.won ? 1 : 0;
        acc.losses += match.won ? 0 : 1;
        return acc;
      },
      {
        totalKills: 0,
        precisionKills: 0,
        assists: 0,
        deaths: 0,
        score: 0,
        wins: 0,
        losses: 0,
      },
    );
    const matchLens = playerMatches.length;
    const kda = calcRate(
      mainStats.totalKills + mainStats.assists,
      mainStats.deaths,
      1,
    );
    const winRate = calcRate(mainStats.wins, matchLens, 2);
    const hsPercent = calcRate(
      mainStats.precisionKills,
      mainStats.totalKills,
      2,
    );

    const score = calcRate(mainStats.score, matchLens, 1);
    return {
      ...mainStats,
      kda,
      score,
      winRate,
      hsPercent,
      matchLens,
    };
  }, [matches, matchlist]);
  return stats;
};

export default useMatchHistoryData;
