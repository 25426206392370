import { readState } from "@/__main__/app-state.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function useUserProfile(bungieIdParam?: string) {
  const {
    destiny2: { profiles, match, matchList, oauth },
  } = useSnapshot(readState);
  const bungieId = bungieIdParam ?? oauth[Object.keys(oauth)[0]]?.bungie_uid;
  const userProfile = profiles[bungieId];
  const matches = matchList[bungieId] ?? [];
  const guardians =
    userProfile?.memberships?.flatMap?.((m) => m.guardians) ?? [];

  return {
    profile: userProfile,
    matchList: matches,
    matchMap: match,
    guardians,
  };
}
