import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import appRefs from "@/app/refs.mjs";
import useProfileWeaponStats from "@/game-destiny2/utils/use-profile-weapon-stats.mjs";
import BlitzLogoLoading from "@/shared/BlitzLogoLoading.jsx";
import DataTable from "@/shared/DataTable.jsx";
import { SORT_DESC } from "@/shared/InfiniteTable.jsx";
import { useEvalState } from "@/util/eval-state.mjs";
import { displayRate, sanitizeNumber } from "@/util/helpers.mjs";
import { formatToFixedNumber } from "@/util/i18n-helper.mjs";
import { lazyComponent } from "@/util/lazy-component.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const WeaponTopStatCards = lazyComponent(
  () => import("@/game-destiny2/components/Profile/WeaponTopStatCards.jsx"),
);

export default function Main() {
  const { t } = useTranslation();
  const {
    parameters: [bungieId],
  } = useRoute();
  const state = useSnapshot(readState);
  const { SignIn } = useSnapshot(appRefs);
  const profiles = state.destiny2.profiles;
  const profile = useEvalState(profiles[bungieId]);
  if (profile?.blitzUser) return <Weapons />;
  return (
    <Card className="flex column text-center" padding="var(--sp-10)">
      <div>
        <div className="type-h6">
          {t("csgo:suggestions.noWeaponKills", "No weapon kills")}
        </div>
        <div className="type-body1-form">
          {t(
            "destiny:suggestions.createBlitzAccountWeaponStats",
            "You must be a Blitz user to receive Destiny 2 weapon stats",
          )}
        </div>
      </div>
      <div>
        <SignIn />
      </div>
    </Card>
  );
}

function Weapons() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    parameters: [bungieId],
  } = useRoute();
  const state = useSnapshot(readState);
  const { profiles } = state.destiny2;
  const $profile = profiles[bungieId];
  const profile = useEvalState($profile);
  const weaponStatsByProfile = useProfileWeaponStats(bungieId);
  const weapons = Object.values(weaponStatsByProfile);
  const totalCount = useMemo(
    () => weaponStatsByProfile.reduce((acc, cur) => acc + cur.count, 0),
    [weaponStatsByProfile],
  );
  const cols = [
    {
      display: t("common:weapon", "Weapon"),
      align: "left",
      isStat: false,
    },
    {
      display: t("common:kills", "Kills"),
      align: "right",
      isStat: true,
    },
    {
      display: t("common:stats.hs", "HS"),
      align: "right",
      isStat: true,
    },
    {
      display: t("val:avgKills", "Avg. Kills"),
      align: "right",
      isStat: true,
    },
    {
      display: t("val:profile.avgHS", "Avg. HS"),
      align: "right",
      isStat: true,
    },
    {
      display: t("common:stats.hsPercent", "HS%"),
      align: "right",
      isStat: true,
    },
    {
      display: t("common:pickRate", "Pick Rate"),
      align: "right",
      isStat: true,
    },
  ];
  const rows =
    weapons.map((i) => {
      const totalKills = i.kills;
      const precisionKillAvg = sanitizeNumber(i.precisionKills / i.count);
      const killAvg = sanitizeNumber(totalKills / i.count);
      return [
        {
          display: i.weapon.name,
          value: i.weapon.name,
        },
        {
          display: formatToFixedNumber(language, totalKills),
          value: totalKills,
        },
        {
          display: formatToFixedNumber(language, i.precisionKills),
          value: i.precisionKills,
        },
        {
          display: formatToFixedNumber(language, killAvg),
          value: killAvg,
        },
        {
          display: formatToFixedNumber(language, precisionKillAvg),
          value: precisionKillAvg,
        },
        {
          display: displayRate(precisionKillAvg, killAvg, 0, 1),
          value: sanitizeNumber(precisionKillAvg / killAvg),
        },
        {
          display: displayRate(i.count, totalCount, 0, 1),
          value: sanitizeNumber(i.count / totalCount),
        },
      ];
    }) ?? [];
  return (
    <div className="flex gap-4 column">
      {!!weapons.length && (
        <WeaponTopStatCards
          fallback={
            <div className="flex gap-4">
              {new Array(3).fill(undefined).map((_, idx) => (
                <div
                  className="relative w-full"
                  key={idx}
                  style={{ height: "180px" }}
                >
                  <Card className="h-full" classNameOuter="h-full" />
                  <div className="absolute-center">
                    <BlitzLogoLoading />
                  </div>
                </div>
              ))}
            </div>
          }
          props={{
            weapons,
            profileImgUrl: profile?.iconPath
              ? `${appURLs.BUNGIE}${profile.iconPath}`
              : "",
          }}
        />
      )}
      <DataTable
        cols={cols}
        rows={rows}
        indexCol
        noResultsTitle={["csgo:suggestions.noWeaponKills", "No weapon kills"]}
        noResultsDescription={[
          "csgo:suggestions.noWeaponKillsDescription",
          "We currently do no have any weapon kills for you.",
        ]}
        sortDir={SORT_DESC}
        sortCol={1}
      />
    </div>
  );
}
