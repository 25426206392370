import type { MapStats } from "@/game-destiny2/components/Profile/types.js";
import type { GuardianMapStats } from "@/game-destiny2/models/destiny2-player-map-stats.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";

export function getGuardianClassAggregates(
  classes: GuardianMapStats["x"],
): MapStats {
  const result = {};
  for (const guardianClass in classes) {
    for (const key in classes[guardianClass]) {
      const value = classes[guardianClass][key];
      if (typeof value !== "number") continue;
      result[key] = sanitizeNumber((result[key] || 0) + value);
    }
  }
  return result as MapStats;
}
