import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { MODES } from "@/game-destiny2/constants/modes.mjs";
import {
  PINNACLE_CAP,
  STARTING_POWER,
} from "@/game-destiny2/constants/wiki-data.mjs";
import type { Match } from "@/game-destiny2/models/destiny2-match.mjs";
import { bungieAsset } from "@/game-destiny2/utils/api.mjs";
import useActivity from "@/game-destiny2/utils/use-activity.mjs";
import getDataTablePills from "@/shared/get-data-table-pills.mjs";
import MatchTileExpand from "@/shared/MatchTileExpand.jsx";
import { filterErrState, useEvalState } from "@/util/eval-state.mjs";
import globals from "@/util/global-whitelist.mjs";
import { calcRate, displayRate, sanitizeNumber } from "@/util/helpers.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const MatchTileTabsEnum = {
  Scoreboard: "scoreboard",
  Statistics: "statistics",
  Hits: "hits",
};

const Tabs = {
  [MatchTileTabsEnum.Scoreboard]: () =>
    import("@/game-destiny2/components/MatchTileScoreboard.jsx"),
  [MatchTileTabsEnum.Statistics]: () =>
    import("@/game-destiny2/components/MatchTileStatistics.jsx"),
  [MatchTileTabsEnum.Hits]: () =>
    import("@/game-destiny2/components/MatchTileHits.jsx"),
};

export default function MatchListRow({
  bungieId,
  match,
}: {
  bungieId: string;
  match: Match;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    destiny2: { characters, profiles, match: matches, matchList },
  } = useSnapshot(readState);
  const profile = useEvalState(profiles[bungieId]);
  const profileGuardians =
    profile?.memberships.flatMap?.((i) => i.guardians) ?? [];
  const $matchlist = useEvalState(matchList[bungieId]);
  const playerMatches = $matchlist
    ?.map((i) =>
      filterErrState(matches[i])?.playerMatches.find((i) =>
        profileGuardians.find((j) => j.characterId === i.guardian.characterId),
      ),
    )
    .filter((i) => i);
  const player = match.playerMatches.find((i) =>
    profileGuardians.find((j) => j.characterId === i.guardian.characterId),
  );
  const isWin = !!player?.won;
  const totalKills = sanitizeNumber(player?.kills);
  const values = useMemo(() => {
    return playerMatches.reduce(
      (acc, cur) => {
        acc.kda.push(
          calcRate(
            (cur?.kills ?? 0) + (cur?.assists ?? 0),
            cur?.deaths ?? 0,
            1,
          ),
        );
        // Exclude score from being considered if this is a world event (patrol) or story
        if (cur.mode !== MODES.patrol && cur.mode !== MODES.story)
          acc.score.push(sanitizeNumber(cur?.score));
        acc.light.push(sanitizeNumber(cur?.lightLevel));
        acc.hsPercentile.push(
          sanitizeNumber((cur?.precisionKills ?? 0) / totalKills),
        );
        return acc;
      },
      { kda: [], score: [], light: [], hsPercentile: [] },
    );
  }, [playerMatches, totalKills]);
  const kda = calcRate(
    totalKills + (player?.assists ?? 0),
    player?.deaths ?? 0,
    1,
  );
  const score = sanitizeNumber(player?.score);
  const light = sanitizeNumber(player?.lightLevel);
  const hsPercentile = sanitizeNumber(
    (player?.precisionKills ?? 0) / totalKills,
  );
  const hsPercent = displayRate(
    player?.precisionKills ?? 0,
    totalKills,
    undefined,
    2,
  );
  const { isStory, isWorldEvent, mode } = useActivity(match);
  const titles = [
    {
      text: isWin
        ? t("common:stats.victory", "Victory")
        : t("common:stats.defeat", "Defeat"),
      bold: true,
      color: isWin ? "var(--turq)" : "var(--red)",
    },
    isStory
      ? {
          text: t("gen-destiny2:activityMode.campaign", "Campaign"),
          bold: false,
          color: "var(--shade2)",
        }
      : isWorldEvent
        ? {
            text: t("gen-destiny2:activityMode.worldEvent", "World Event"),
            bold: false,
            color: "var(--shade2)",
          }
        : undefined,
    mode
      ? {
          text: mode,
          bold: false,
          color: "var(--shade2)",
        }
      : undefined,
  ].filter(Boolean);
  return (
    <MatchTileExpand
      isWin={isWin}
      image={{
        src: bungieAsset(
          filterErrState(characters[bungieId]?.[player?.guardian.characterId])
            ?.character?.emblemPath ?? "",
        ),
        alt: player?.guardian.name ?? "",
      }}
      titles={titles}
      tabDefault={MatchTileTabsEnum.Scoreboard}
      tabsToElement={Tabs}
      tabsLabel={
        [
          {
            name: ["tft:matchtabs.scoreboard", "Scoreboard"],
            key: MatchTileTabsEnum.Scoreboard,
          },
          {
            name: ["common:navigation.statistics", "Statistics"],
            shortName: ["common:stats", "Stats"],
            key: MatchTileTabsEnum.Statistics,
          },
          {
            name: ["common:stats.kills", "Kills"],
            key: MatchTileTabsEnum.Hits,
          },
        ] as Array<{ name: Translation; key: string; shortName?: Translation }>
      }
      tabArgs={{
        gameId: match.activityId,
        bungieId,
      }}
      stats={[
        {
          top: t("common:stats.kda", "{{ kda, number }} KDA", {
            kda: kda,
          }),
          pill: getDataTablePills(kda, [kda, ...values.kda]),
          bottom: [totalKills, player?.deaths, player?.assists]
            .map((i) => sanitizeNumber(i).toLocaleString(language))
            .join(" / "),
        },
        {
          top: light.toLocaleString(language),
          pill: getDataTablePills(light, [STARTING_POWER, PINNACLE_CAP]),
          bottom: t("destiny2:stats.light", "Light"),
        },
        {
          top: hsPercent,
          pill: getDataTablePills(hsPercentile, [
            hsPercentile,
            ...values.hsPercentile,
          ]),
          bottom: t("common:stats.hsPercent", "HS%"),
        },
        isStory || isWorldEvent
          ? undefined
          : {
              top: score.toLocaleString(language),
              pill: getDataTablePills(score, [score, ...values.score]),
              bottom: t("common:stats.score", "Score"),
            },
      ].filter((i) => i)}
      urlWeb={`${globals.location.origin}/destiny2/match/${bungieId}/${match.activityId}`}
      urlCanonical={`/destiny2/match/${bungieId}/${match.activityId}`}
      aside={
        match.playerMatches.length && (
          <PlayerGrid
            $columns={Math.ceil(
              sanitizeNumber(match.playerMatches.length / 5) || 1,
            )}
          >
            {match.playerMatches.map((i) => (
              <div
                className="type-caption guardian-name"
                key={i.guardian.characterId + i.guardian.name}
              >
                {i.guardian.name}
              </div>
            ))}
          </PlayerGrid>
        )
      }
      matchDate={new Date(match.startedAt)}
    />
  );
}

const PlayerGrid = styled<{ $columns: number }>("div")`
  display: grid;
  grid-template-columns: repeat(
    ${({ $columns }) => $columns},
    minmax(50px, 100px)
  );
  & .round {
    flex-shrink: 0;
  }
  & .guardian-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & .guardian-pfp {
    width: var(--sp-4);
    height: var(--sp-4);
    flex-shrink: 0;
  }
`;
