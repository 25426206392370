import { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import type { GuardianWeaponStats } from "@/game-destiny2/models/destiny2-player-weapon-stats.mjs";
import { filterErrState, useEvalState } from "@/util/eval-state.mjs";
import { useQuery } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function useProfileWeaponStats(bungieId: string) {
  const state = useSnapshot(readState);
  const { seasons, weaponStats } = state.destiny2;
  const [guardian] = useQuery("guardian");
  const [seasonId] = useQuery("season", seasons[0]?.id);
  const $profile = state.destiny2.profiles[bungieId];
  const profile = useEvalState($profile);
  const guardians = useMemo(
    () =>
      seasonId
        ? (profile?.memberships.flatMap?.((i) => i.guardians) ?? [])
        : [],
    [seasonId, profile?.memberships],
  );
  const weaponStatsByProfile = useMemo<
    Array<GuardianWeaponStats["weaponAggregates"][0]>
  >(() => {
    if (!seasonId) return [];
    const weapons: Record<string, GuardianWeaponStats["weaponAggregates"][0]> =
      {};
    for (const g of guardians) {
      const i = filterErrState(weaponStats[btoa(seasonId + g.characterId)]);
      if (!i || (guardian && i?.class !== guardian)) continue;
      for (const j of i.weaponAggregates) {
        if (weapons[j.weapon.id]) {
          for (const o in weapons[j.weapon.id]) {
            const value = weapons[j.weapon.id][o];
            if (typeof value !== "number") continue;
            weapons[j.weapon.id][o] = (value ?? 0) + j[o];
          }
        } else {
          weapons[j.weapon.id] = { ...j }; // Shallow clone
        }
      }
    }
    return Object.values(weapons)
      .sort((a, b) => b.kills - a.kills)
      .slice(0, 10);
  }, [weaponStats, guardian, guardians, seasonId]);
  return weaponStatsByProfile;
}
